import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionsNotifications } from 'src/app/providers/NotificationsProvider/_BLL/notifications/slice';
import { rtkApiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { ActiveListOptions, DocumentInfo, IDocumentType, Pagination, SourceReportOptions, TypeInfo } from './types/types';
import { DocumentsCounts_REQ, DocumentsLookup_REQ, DocumentUpdate_REQ, DocumentUpload_REQ } from './types/types_REQ';
import {
	DocumentsCounts_RES,
	DocumentsLookup_RES,
	DocumentsTypes_RES,
	DocumentsTypesInfo_RES,
	DocumentsYears_RES,
	DocumentUpdate_RES,
	DocumentUpload_RES,
} from './types/types_RES';

const NAME = 'dashboard_documents';

// * Thunks
const getDocumentsCounts = createAsyncThunk(`${NAME}/getDocumentsCount`, async (arg: DocumentsCounts_REQ, thunkAPI) => {
	const { params } = arg;

	return await rtkApiRequest.rtkGETRequest<DocumentsCounts_RES>({
		url: UrlAPI.documentsCounts,
		params,
		thunkAPI,
	});
});

const getDocumentsYears = createAsyncThunk(`${NAME}/getDocumentsYears`, async (_: void, thunkAPI) => {
	return await rtkApiRequest.rtkGETRequest<DocumentsYears_RES>({
		url: UrlAPI.documentsYears,
		thunkAPI,
	});
});

const getDocumentsTypes = createAsyncThunk(`${NAME}/getDocumentsTypes`, async (_: void, thunkAPI) => {
	return await rtkApiRequest.rtkGETRequest<DocumentsTypes_RES>({
		url: UrlAPI.documentsTypes,
		thunkAPI,
	});
});

const getDocumentsTypesInfo = createAsyncThunk(`${NAME}/getDocumentsTypesInfo`, async (_: void, thunkAPI) => {
	return await rtkApiRequest.rtkGETRequest<DocumentsTypesInfo_RES>({
		url: UrlAPI.documentsTypesInfo,
		thunkAPI,
	});
});

const documentsLookup = createAsyncThunk(`${NAME}/documentsLookup`, async (arg: DocumentsLookup_REQ, thunkAPI) => {
	const { payload } = arg;

	return await rtkApiRequest.rtkPOSTRequest<DocumentsLookup_RES>({
		url: UrlAPI.documentsLookup,
		payload,
		thunkAPI,
	});
});

const documentUpload = createAsyncThunk(`${NAME}/documentUpload`, async (arg: DocumentUpload_REQ, thunkAPI) => {
	const { payload } = arg;
	const { dispatch } = thunkAPI;

	const res = await rtkApiRequest.rtkPOSTRequest<DocumentUpload_RES>({
		url: UrlAPI.documentsUpload,
		payload,
		thunkAPI,
	});

	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: `Document ${res.name} uploaded successfully`,
		}),
	);

	return res;
});

const documentUpdate = createAsyncThunk(`${NAME}/documentUpdate`, async (arg: DocumentUpdate_REQ, thunkAPI) => {
	const { payload } = arg;
	const { dispatch } = thunkAPI;

	const res = await rtkApiRequest.rtkPUTRequest<DocumentUpdate_RES>({
		url: UrlAPI.documentsUpdate,
		payload,
		thunkAPI,
	});

	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: `Document ${res.name} updated successfully`,
		}),
	);

	return res;
});

// * Reducer
interface State {
	pagination: Pagination;
	documents: DocumentInfo[];
	counts: DocumentsCounts_RES | null;
	years: number[];
	types: IDocumentType[];
	typesInfo: TypeInfo[];
	status: RequestStatus;

	activeListOptions: ActiveListOptions;
	sourceReportOptions: SourceReportOptions;
	companyDocuments: DocumentInfo[];
}

export const initialState: State = {
	pagination: {
		pageSize: 0,
		pageIndex: 0,
		pageCount: 0,
		count: 0,
	},
	documents: [],
	counts: null,
	years: [],
	types: [],
	typesInfo: [],
	status: RequestStatus.still,

	activeListOptions: {
		year: null,
		type: null,
	},
	sourceReportOptions: [],
	companyDocuments: [],
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setActiveListOptions(state, action: PayloadAction<ActiveListOptions>) {
			state.activeListOptions = action.payload;
		},
		setSourceReportOptions(state, action: PayloadAction<SourceReportOptions>) {
			state.sourceReportOptions = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getDocumentsCounts.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDocumentsCounts.fulfilled, (state, action) => {
			state.counts = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDocumentsCounts.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getDocumentsYears.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDocumentsYears.fulfilled, (state, action) => {
			state.years = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDocumentsYears.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getDocumentsTypes.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDocumentsTypes.fulfilled, (state, action) => {
			state.types = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDocumentsTypes.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getDocumentsTypesInfo.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDocumentsTypesInfo.fulfilled, (state, action) => {
			state.typesInfo = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDocumentsTypesInfo.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(documentsLookup.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(documentsLookup.fulfilled, (state, action) => {
			const { saveTo } = action.meta.arg.options;

			state.pagination = action.payload.pagination;
			state.documents = action.payload.documents;
			state.status = RequestStatus.still;

			if (saveTo === 'company') {
				state.companyDocuments = action.payload.documents;
			} else {
				state.documents = action.payload.documents;
			}
		});
		builder.addCase(documentsLookup.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(documentUpload.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(documentUpload.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(documentUpload.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(documentUpdate.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(documentUpdate.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(documentUpdate.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsDocuments = {
	...slice.actions,
	getDocumentsCounts,
	getDocumentsYears,
	getDocumentsTypes,
	getDocumentsTypesInfo,
	documentsLookup,
	documentUpload,
	documentUpdate,
};
