import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/app/redux/rootReducer';
import { rtkApiRequest } from 'src/shared/api/api';
import { RequestStatus } from 'src/shared/api/types';
import { actionsNotifications } from '../../../../app/providers/NotificationsProvider/_BLL/notifications/slice';
import { actionsMetaDictionaries } from '../../../../app/redux/slices/metaDictionaries/slice';
import { actionsDashboardCompany } from '../company/slice';
import { Company } from '../company/types/types';
import { actionsDashboardPeople } from '../people/slice';
import { PeopleRES } from '../people/types';
import { generateMenu } from './_helpers/generateMenu';
import {
	CompanyOverview,
	CompanyOverviewRes,
	GetTeamNotesREQ,
	GetTeamNotesRES,
	Header,
	PostTeamNotesREQ,
	PostTeamNotesRES,
	PutTeamNotesREQ,
	SingleMenuItem,
	TeamNote,
} from './types/types';

const NAME = 'dashboard';

// * Thunks
const getCompanyOverview = createAsyncThunk(`${NAME}/getCompanyOverview`, async (payload: { selectedCompanyId: number | string }, thunkAPI) => {
	const { selectedCompanyId } = payload;

	return await rtkApiRequest.rtkGETRequest<CompanyOverviewRes>({
		url: 'companyOverview',
		params: {
			id: selectedCompanyId,
		},
		thunkAPI,
	});
});

const getCompany = createAsyncThunk(`${NAME}/getCompany`, async (payload: { selectedCompanyId: number | string; reportingPeriodId?: number }, thunkAPI) => {
	const { selectedCompanyId, reportingPeriodId } = payload;
	const { dispatch } = thunkAPI;

	const resCompany = await rtkApiRequest.rtkGETRequest<Company>({
		url: 'company',
		params: {
			id: selectedCompanyId,
			reporting_period_id: reportingPeriodId,
		},
		thunkAPI,
	});

	dispatch(actionsDashboardCompany.companyStore(resCompany));

	thunkAPI.dispatch(actionsDashboard.setActivePeriod(resCompany.reportingPeriodId));
});

const getPeople = createAsyncThunk(`${NAME}/getPeople`, async (payload: { selectedCompanyId: number | string; reportingPeriodId?: number }, thunkAPI) => {
	const { selectedCompanyId, reportingPeriodId } = payload;
	const { dispatch } = thunkAPI;

	const resPeople = await rtkApiRequest.rtkGETRequest<PeopleRES>({
		url: 'people',
		params: {
			company_id: selectedCompanyId,
			reporting_period_id: reportingPeriodId,
			page_size: 1000,
			page_number: 1,
		},
		thunkAPI,
	});

	dispatch(
		actionsDashboardPeople.storePerson({
			people: resPeople.people,
			peoplePagination: resPeople.pagination,
		}),
	);
});

const generateMenuItemsWithProgressAndPriority = createAsyncThunk(`${NAME}/generateMenuItemsWithProgressAndPriority`, async (payload: void, thunkAPI) => {
	const { getState } = thunkAPI;
	const state = getState() as RootState;

	const companyMeta = state.metaDictionaries.meta.company;
	const currentCompany = state.dashboardCompany.currentCompany;
	const people = state.dashboardPeople.people;

	if (!companyMeta || !currentCompany) {
		return null;
	} else {
		const menu = generateMenu(companyMeta, currentCompany, people);
		return menu;
	}
});

const getTeamNotes = createAsyncThunk(`${NAME}/getTeamNotes`, async (args: GetTeamNotesREQ, thunkAPI) => {
	const { params } = args;

	return await rtkApiRequest.rtkGETRequest<GetTeamNotesRES>({
		url: 'teamNotes',
		params,
		thunkAPI,
	});
});

const saveTeamNote = createAsyncThunk(`${NAME}/saveTeamNote`, async (args: PostTeamNotesREQ, thunkAPI) => {
	const { dispatch } = thunkAPI;
	const { payload } = args;

	const res = await rtkApiRequest.rtkPOSTRequest<PostTeamNotesRES>({
		url: 'teamNotes',
		payload,
		thunkAPI,
	});

	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: 'Note is added',
		}),
	);

	return res;
});

const editTeamNote = createAsyncThunk(`${NAME}/editTeamNote`, async (args: PutTeamNotesREQ, thunkAPI) => {
	const { dispatch } = thunkAPI;
	const { payload } = args;

	const res = await rtkApiRequest.rtkPUTRequest<TeamNote>({
		url: 'teamNotes',
		payload,
		thunkAPI,
	});

	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: 'Note is updated',
		}),
	);

	return res;
});

// * Combined Thunks
const initialize = createAsyncThunk(`${NAME}/initializeWorker`, async (payload: { companyId: number; reportingPeriodId: number | undefined }, thunkAPI) => {
	const { dispatch } = thunkAPI;
	const { companyId, reportingPeriodId } = payload;

	const promise1 = dispatch(actionsMetaDictionaries.getMeta({}));
	const promise2 = dispatch(getCompanyOverview({ selectedCompanyId: companyId }));
	const promise3 = dispatch(getCompany({ selectedCompanyId: companyId, reportingPeriodId }));
	const promise4 = dispatch(getPeople({ selectedCompanyId: companyId, reportingPeriodId }));
	const promise5 = dispatch(getTeamNotes({ params: { company_id: companyId } }));
	return Promise.allSettled([promise1, promise2, promise3, promise4, promise5])
		.then(() => dispatch(generateMenuItemsWithProgressAndPriority()))
		.catch(() => console.log('error'));
});

// * Reducer
interface State {
	header: Header | null;
	overview: CompanyOverview | null;
	menuItems: SingleMenuItem[] | null;
	activePeriodId: number | null;
	activeEditMode: string | null;
	includeUnknown: boolean /* Include unknown in progress and priority calculation */;
	teamNotes: TeamNote[];
	status: RequestStatus;
}

export const initialState: State = {
	header: null,
	overview: null,
	menuItems: null,
	activePeriodId: null,
	activeEditMode: null,
	includeUnknown: false,
	teamNotes: [],
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setActivePeriod: (state, action: { payload: number }) => {
			state.activePeriodId = action.payload;
		},
		toggleEditMode: (state, action: { payload: string | null }) => {
			state.activeEditMode = action.payload;
		},
		toggleIncludeUnknown: (state, action: { payload: boolean }) => {
			state.includeUnknown = action.payload;
		},
		setStatus: (state, action: { payload: RequestStatus }) => {
			state.status = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getCompanyOverview.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompanyOverview.fulfilled, (state, action) => {
			state.header = action.payload.header;
			state.overview = action.payload.overview;
			state.status = RequestStatus.still;
		});
		builder.addCase(getCompanyOverview.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getCompany.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompany.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(getCompany.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getPeople.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getPeople.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(getPeople.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(generateMenuItemsWithProgressAndPriority.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(generateMenuItemsWithProgressAndPriority.fulfilled, (state, action) => {
			state.menuItems = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(generateMenuItemsWithProgressAndPriority.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getTeamNotes.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getTeamNotes.fulfilled, (state, action) => {
			state.teamNotes = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getTeamNotes.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(saveTeamNote.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(saveTeamNote.fulfilled, (state, action) => {
			state.teamNotes = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(saveTeamNote.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editTeamNote.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editTeamNote.fulfilled, (state, action) => {
			const updatedNote = action.payload;
			state.teamNotes = state.teamNotes.map(note => (note.id === updatedNote.id ? updatedNote : note));
			state.status = RequestStatus.still;
		});
		builder.addCase(editTeamNote.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(initialize.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(initialize.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(initialize.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsDashboard = {
	...slice.actions,
	getCompanyOverview,
	getCompany,
	getPeople,
	generateMenuItemsWithProgressAndPriority,
	saveTeamNote,
	editTeamNote,
	initialize,
};
