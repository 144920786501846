import { SelectProps, TreeSelect } from 'antd';
import { FC, useId } from 'react';
import { InputWrapper } from '../_common/InputWrapper';

// TODO: [to Helena] REFACTOR!

export interface Option {
	// ! [to Helena] is not used anywhere
	id: number | string | null | undefined; // id is an actual value of the select.
	name: string; // name for display.
}

interface Props extends SelectProps {
	options: Array<Object>; // ! [to Helena] is not used anywhere should be Option[]
	addEmpty?: boolean;
	defaultValue?: any; // ! [to Helena] is not used anywhere should be Option
	label?: string;
	placeholder?: string;
	error?: string;
}
/**
 * TreeSelect with dictionary, see SelectDictionary for another version.
 */
export const TreeSelectDictionary: FC<Props> = props => {
	const {
		options, //
		addEmpty,
		defaultValue,

		label, //
		placeholder,
		error,
		status,
		className,
		...selectProps
	} = props;

	const id = useId();

	return (
		<InputWrapper
			label={label}
			error={error}
			className={className}
			id={id}
		>
			{addEmpty ? (
				<TreeSelect
					{...selectProps}
					treeData={[
						{
							value: '',
							title: '',
						},
						...options,
					]}
					value={defaultValue}
					placeholder={placeholder}
				/>
			) : (
				<TreeSelect
					{...selectProps}
					treeData={options}
					value={defaultValue}
					placeholder={placeholder}
				/>
			)}
		</InputWrapper>
	);
};
